import React, { useState } from 'react';
import tw, {styled} from "twin.macro";
// import styled from "styled-components";
// import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../../../../ui-treact/components/headers/light.js";
// import { Link } from "../../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general.js";
import MainHeader from "../../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/custom-sections/Header/main-header.js";

// import ResponsiveVideoEmbed from "src/ui-treact/helpers/ResponsiveVideoEmbed.js";
import Typewriter from 'typewriter-effect';
import HeroLogo from '../../../../images/hero2.jpg'
import { Link } from "../../../utils/general.js";
// const StyledHeader = styled(Header)`
//   ${tw`pt-8 max-w-none`}
//   ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
//     ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
//   }
//   ${NavToggle}.closed {
//     ${tw`text-gray-100 hover:text-primary-500`}
//   }
// `;
const Container = styled.div`
  ${tw`relative -mt-8 bg-center bg-cover`}
  background-image: url(${HeroLogo});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-16 pb-24 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
// const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none h-48`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 pb-3 min-h-16 md:min-h-16 lg:min-h-20 xl:min-h-24`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

// const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;
const PrimaryAction = styled(Link)`
${tw` px-8 py-3 font-bold rounded bg-white text-primary-900 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0 hover:no-underline cursor-pointer`}
`
// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        sub_heading: "Our Track Record",
        heading: "Hire the best",
        slanted: "Marketing Team.",
        notification: "We have now launched operations in Europe.",
        primary_button_url:"",
        primary_button_text:"Contact Us",
        ...finalData
    }

    const [showSecondHeadline, SetshowSecondHeadline] = useState(false);

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer tw="py-8">
      <MainHeader bgColor="bg-transparent" />

        <TwoColumn>
          <LeftColumn>
            <Notification>
            {content.notification}
            </Notification>
            <Heading>
              {/* <div dangerouslySetInnerHTML={{ __html: content.heading }}></div> */}
              <div tw="h-24">
              <Typewriter
                options={{
                    cursor:"",
                    cursorClassName:""
                }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        `<span style="color: #fff;">${content.heading}</span>`
                      )
                      .callFunction(() => {
                        SetshowSecondHeadline(true);
                      }) 
                      .start();
                  }}
                />
              </div>
              
              {showSecondHeadline && <SlantedBackground>
                {/* {content.slanted} */}
                <Typewriter
                    options={{
                      autoStart: true,
                      cursor:"",
                      cursorClassName:"",
                      delay: 75,
                      loop: true,
                      strings: [
                        `<span style="color: #ff9623;">Marketing Team</span>`,
                        '<span style="color: #ff9623; ">Page Optimization Team</span>',
                        '<span style="color: #ff9623; ">SEO Team</span>',
                        '<span style="color: #ff9623; ">Data Analytics Team</span>',
                        '<span style="color: #ff9623; ">Design Team</span>'
                        ],
                    }}
                  />
                </SlantedBackground>}
            </Heading>
            <PrimaryAction to="/pages/contact-us">{content.primary_button_text}</PrimaryAction>
          </LeftColumn>
          {/* <RightColumn>
            <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            />
          </RightColumn> */}
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};